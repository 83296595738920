import React from 'react';

type Props = {
  fillColor?: string,
  strokeColor?: string,
  strokeWidth?: string,
  customClassName?: string,
  isPrompt?: boolean
  onClick?: () => void,
} & typeof defaultProps;

const defaultProps = {
  fillColor: '#fff',
  strokeColor: '#1EC252',
  strokeWidth: '1.8',
  customClassName: '',
  isPrompt: false,
  onClick: () => { },
};
const SingleFavoriteIcon = function SingleFavoriteIcon(props: Props) {
  const {
    fillColor, onClick, strokeColor, strokeWidth, customClassName, isPrompt,
  } = props;
  const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (isPrompt) {
      event.stopPropagation();
      onClick();
    }
  };

  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`custom-favorite-icon ${customClassName}`}
      onClick={handleClick}
    >
      <path id="Shape (Stroke)" d="M12.5957 6.49292C12.7279 6.79641 13.016 7.00283 13.3459 7.03037L19.3571 7.53224L14.816 11.3613C14.5559 11.5806 14.4417 11.9277 14.5208 12.2586L15.888 17.9778L10.6863 14.9254C10.405 14.7603 10.0565 14.7603 9.77526 14.9254L4.57357 17.9778L5.94075 12.2586C6.01983 11.9277 5.90563 11.5806 5.64557 11.3613L1.10447 7.53224L7.11562 7.03037C7.44551 7.00283 7.73365 6.79641 7.86586 6.49292L10.2308 1.06393L12.5957 6.49292Z" fill={fillColor} stroke={strokeColor} strokeWidth={strokeWidth} strokeLinejoin="round" />
    </svg>
  );
};
SingleFavoriteIcon.defaultProps = defaultProps;
export default SingleFavoriteIcon;
