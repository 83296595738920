import React, { ReactNode, useEffect, useState } from 'react';
import { Layout } from 'antd';
import { notificationMessage } from 'Selector/HelperSelector';
import { useDispatch, useSelector } from 'react-redux';
import {
  certificationSmoothBehavior,
  getLocalStorageValue,
  hamBurgerCheck,
  isSalesforce,
  IsTablet,
  setLocalStorageValue,
  showCertificationInApp,
} from 'Utils/UtilityFunctions';
import ToasterNotification from 'Components/common/components/ToasterNotification';
import WelcomeTourPopup from 'Components/common/modal/WelcomeTourPopup';
import DrawerCertification from 'Components/certification/components/DrawerCertification';
import {
  selectActiveModule,
  selectActiveTask,
  selectActivetype,
  selectData,
  selectHighlight,
  selectIsCertificationCompleted, selectIsHighlighted, selectIsScroll, selectOpenDrawer,
  selectPopupData, selectScrollToId, selectScrollToPage,
} from 'Selector/CertificationSelector';
import { gillRoyCertificationComplete, gillRoyTaskComplete } from 'Utils/utilsStyle';
import CertificationCompletionPopup from 'Components/common/modal/CertificationCompletionPopup';
import MarketingPopup from 'Components/common/modal/MarketingPopup';
import {
  selectLoading, selectMarketingPopup, selectApiCalled, selectShowMappingPopup,
  selectGilroyDiscalimer,
  selectRestrictAppAccess,
  selectDisclessPersonality,
  selectLoaded,
} from 'Selector/GlobalSearchContentSelector';
import _ from 'lodash';
import { actions as UiAction } from 'Slice/UISlice';
import {
  CERTIFICATION_COMP_POPUPS, CERTIFICATION_START_POPUP, COMPANIES_ROUTE, PEOPLE_ROUTE,
} from 'Utils/Constants';
import classNames from 'classnames';
import MenuDrawer from 'Components/common/components/MenuDrawer';
import ProfileConfirmationPopup from 'Components/common/modal/ProfileConfirmationPopup';
import { selectSmartAccountToastMessage } from 'Selector/CompaniesSelector';
import { actions as flurryActions } from 'Slice/HelperSlice';
import {
  selectAlertDrawerVisible, selectArticle, selectSimilarArticleCount,
  selectSimilarArticleCountTwo,
  selectSimilarArticleDetail, selectSimilarArticleTwo, selectSimilarArticles,
  selectSimilarDrawerVisible,
} from 'Selector/ArticleSector';
import ArticleDrawer from 'Components/common/components/ArticleDrawer';
import {
  actions as articleHelper,
} from 'Slice/ArticlesSlice';
import UserCompliancePopup from 'Components/common/components/UserCompliancePopup';
// import { actions as profileMapActions } from '../../../Slice/ProfileConfirmationSlice';
import { isMobile, isTablet } from 'react-device-detect';
import LimitAccessPopup from 'Components/common/modal/LimitAccessPopup';

import { selectCompliancePopup } from 'Selector/LoginSelector';
import { StatesAndActionsWrapper } from 'gilroy-global';
import { ChatFeedback } from 'Types/RequestBodies';
import { actions as certificationActions } from '../../../Slice/CertificationSlice';
// import { actions as profileMapActions } from '../../../Slice/ProfileConfirmationSlice';
import { actions as globalContentActions } from '../../../Slice/GlobalSearchContentSlice';

const { Header } = Layout;

type Props = {
  children: ReactNode
  headerClassName?: string,
} & typeof defaultProps;

const defaultProps = {
  headerClassName: '',
};

interface GetStates {
  saveProfileName: string,
  saveUrl: string,
  chatLoading: boolean,
}

interface GetActions {
  setCertificationPrompt: (data: string) => void,
  sendFeedback: (data: ChatFeedback) => void,
  resetChatbotPopup: () => void,
  setSaveUrl: (data: string) => void,
  setSaveProfileName: (data: string) => void,
  setShowLibrary: (data: boolean) => void,
  setShowExamplePrompts: (data: boolean) => void,
  resetChatHistory: () => void,
  setSelectedConversationId: (data: number) => void,
  setHistoryPanelOpened: (data: boolean) => void,
}

interface getStatesAndActionsType {
  chatStates: GetStates
  chatActions: GetActions
}

let chatActions = {
  setCertificationPrompt: (a: string) => { },
  sendFeedback: (data: ChatFeedback) => { },
  resetChatbotPopup: () => { },
  setSaveUrl: (data: string) => { },
  setSaveProfileName: (data: string) => { },
  setShowLibrary: (data: boolean) => { },
  setShowExamplePrompts: (data: boolean) => { },
  resetChatHistory: () => { },
  setSelectedConversationId: (data: number) => { },
  setHistoryPanelOpened: (data: boolean) => { },
};

let chatStates = {} as GetStates;

const NavBarPrimary = function NavBarPrimary(props: Props) {
  const welcomePopupFlag = getLocalStorageValue('welcomePopupFlag');
  const [showToaster, setShowToaster] = useState(false);
  const MarketingPopupResponse = useSelector(selectMarketingPopup);
  const popupResponse = (MarketingPopupResponse && MarketingPopupResponse[0]) || [];
  const dispatch = useDispatch();
  const [showWelcome] = useState(getLocalStorageValue(CERTIFICATION_START_POPUP) !== 'false');
  const [completionPopupFlagLocal, setCompletionPopupFlagLocal] = useState(false);
  const { children, headerClassName } = props;
  const toasterMessage = useSelector(notificationMessage);
  const LoaderPopup = useSelector(selectLoading);
  const showCertification = useSelector(selectOpenDrawer);
  const { data } = useSelector(selectData);
  const certified = useSelector(selectIsCertificationCompleted);
  const popupData = useSelector(selectPopupData);
  const apiCalled = useSelector(selectApiCalled);
  const alertDrawerVisible = useSelector(selectAlertDrawerVisible);
  const similarDrawerVisible = useSelector(selectSimilarDrawerVisible);
  const article = useSelector(selectArticle);
  const similarArticlesCount = useSelector(selectSimilarArticleCount);
  const similarArticlesCountTwo = useSelector(selectSimilarArticleCountTwo);
  const similarArticlesTwo = useSelector(selectSimilarArticleTwo);
  const similarArticles = useSelector(selectSimilarArticles);
  const similarArticleDetail = useSelector(selectSimilarArticleDetail);
  const globalSearchTopicsLoaded = useSelector(selectLoaded);
  const isTab = IsTablet();
  const {
    completed, type, text, points, isAi,
  } = popupData;

  useEffect(() => {
    if (toasterMessage !== '') {
      setShowToaster(true);
    } else {
      setShowToaster(false);
    }
  }, [toasterMessage]);

  useEffect(() => {
    if (welcomePopupFlag !== 'true') {
      setLocalStorageValue('welcomePopupFlag', 'true');
    }
  }, []);

  const closeAction = () => {
    dispatch(certificationActions.resetPopupData());
  };

  const getPopupImage = (popupType: string) => {
    if (popupType === 'Certificate') return gillRoyCertificationComplete;
    return gillRoyTaskComplete;
  };

  const onClose = () => {
    dispatch(articleHelper.setAlertDrawerVisible(false));
    dispatch(UiAction.isSimilarArticleScroll(false));
  };

  const onCloseSimilar = () => {
    dispatch(articleHelper.setSimilarDrawerVisible(false));
    dispatch(articleHelper.resetArticleTwo());
    dispatch(UiAction.isSimilarArticleScrollItem(false));
  };

  const isScroll = useSelector(selectIsScroll);
  const isHighlight = useSelector(selectHighlight);
  const currentType = useSelector(selectActivetype);
  const scrollToId: string = useSelector(selectScrollToId);
  const scrollToPage: string = useSelector(selectScrollToPage);
  const isHighlighted = useSelector(selectIsHighlighted);
  const activeTask = useSelector(selectActiveTask);
  const activeModule = useSelector(selectActiveModule);
  const isMappingPopup = useSelector(selectShowMappingPopup);
  const { showDisclaimer } = useSelector(selectGilroyDiscalimer);
  const AccountPlanToastMessage = useSelector(selectSmartAccountToastMessage);
  const { message, companyId } = AccountPlanToastMessage;
  const { visible } = useSelector(selectCompliancePopup);
  const restrictAppAccess = useSelector(selectRestrictAppAccess);
  const isDiscLessPersonality = useSelector(selectDisclessPersonality);

  const gilroyStatesAndActions = (params: getStatesAndActionsType) => {
    dispatch(certificationActions.setChatLoading(params.chatStates.chatLoading));
    chatStates = params.chatStates;
    chatActions = params.chatActions;
  };

  useEffect(() => {
    setCompletionPopupFlagLocal(getLocalStorageValue(CERTIFICATION_COMP_POPUPS) !== 'false');
  });
  useEffect(() => {
    dispatch(flurryActions.setNotification({
      message,
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: true,
      type: 'accountPlan',
      companyId,
    }));
  }, [AccountPlanToastMessage]);

  useEffect(() => {
    if (isTab) {
      chatActions.setHistoryPanelOpened(false);
    }
    dispatch(flurryActions.setNotification({
      message: '',
      proTip: false,
      rightPlacement: false,
      groupId: 0,
      classnameFlag: true,
    }));
    return () => {
      dispatch(flurryActions.setNotification({
        message: '',
        proTip: false,
        rightPlacement: false,
        groupId: 0,
        classnameFlag: true,
      }));
    };
  }, []);

  useEffect(() => {
    if (isMappingPopup) {
      // dispatch(profileMapActions.setPopupVisible(true));
      dispatch(globalContentActions.setShowMappingPopup(false));
    }
  }, [isMappingPopup]);

  useEffect(() => {
    if (isScroll && ((scrollToPage.includes(`${PEOPLE_ROUTE}`) && currentType !== 'person_drag') || scrollToPage.includes(COMPANIES_ROUTE))) {
      certificationSmoothBehavior(scrollToId);
      dispatch(certificationActions.setIsScroll(false));
    }
  }, [scrollToId, isScroll]);

  useEffect(() => {
    if (!isHighlighted && !isScroll && (currentType === 'company_search' || currentType === 'person_search')) {
      dispatch(certificationActions.setHighlight(true));
      dispatch(certificationActions.setIsHighlighted(false));
    }
  }, [isScroll, currentType]);

  useEffect(() => {
    if ((completed && !activeTask && !completionPopupFlagLocal && type !== 'Certificate')
      || (completed && !!activeTask && (activeTask.header !== text && activeModule?.header !== text) && type !== 'Certificate')) { closeAction(); }
  }, [completed, activeTask, completionPopupFlagLocal, type]);

  useEffect(() => {
    const node = document.querySelector('body');
    return alertDrawerVisible ? node?.classList.add('body-drawer-toggle') : node?.classList.remove('body-drawer-toggle');
  }, [alertDrawerVisible]);

  return (
    <Header className={classNames(
      `header-main-wrapper ${headerClassName}`,
      { certificationIndex: isHighlight && (currentType === 'company_search' || currentType === 'person_search') },
    )}
    >
      {showToaster ? (
        <ToasterNotification />
      ) : null}
      {MarketingPopupResponse.length !== 0 && popupResponse?.read === false
        && !((showDisclaimer && (isMobile || isTablet)) || restrictAppAccess || visible)
        ? (
          <MarketingPopup
            loaderPopup={LoaderPopup}
            marketingPopup={popupResponse}
          />
        ) : null}
      <div className={classNames('container h-100 o-revert', { fStructure: IsTablet() })}>
        {isSalesforce() || IsTablet()
          ? (
            <div className={classNames('responsive-logo', { noHistory: !hamBurgerCheck() })}>
              {hamBurgerCheck() ? (
                <MenuDrawer />
              ) : null}
            </div>
          )
          : null}
        <div className="header-wrapper">
          {children}
        </div>
        {
          alertDrawerVisible
          && (
            <ArticleDrawer
              visible={alertDrawerVisible}
              articleId={article?.articleId}
              title={article?.title}
              domain={article?.domain}
              dateTimeStamp={article?.dateTimeStamp}
              image={article?.image}
              summary={article?.summary}
              url={article?.url}
              articleAlert={article?.alerts}
              onClose={onClose}
              similarArticles={similarArticles}
              similarArticlesCount={similarArticlesCount}
              companyName={article?.companyName}
            />
          )
        }
        {
          similarDrawerVisible
          && (
            <ArticleDrawer
              visible={similarDrawerVisible}
              articleId={similarArticleDetail?.articleId}
              title={similarArticleDetail?.title}
              domain={similarArticleDetail?.domain}
              dateTimeStamp={similarArticleDetail?.dateTimeStamp}
              image={similarArticleDetail?.image}
              summary={similarArticleDetail?.summary}
              url={similarArticleDetail?.url}
              articleAlert={similarArticleDetail?.alerts}
              onClose={onCloseSimilar}
              similarArticles={similarArticlesTwo}
              similarArticlesCount={similarArticlesCountTwo}
              companyName={similarArticleDetail?.companyName}
              isSimilarArticle
            />
          )
        }
      </div>
      {showCertificationInApp()
        ? (
          <>
            {!isDiscLessPersonality ? (
              <CertificationCompletionPopup
                show={
                  (completed && !!activeTask
                    && ((activeTask?.header === text && type === 'Task') || (activeModule?.header === text && type === 'Module')))
                  || (completed && !activeTask && completionPopupFlagLocal)
                  || (completed && type === 'Certificate')
                }
                certification={type === 'Certificate'}
                onCancel={closeAction}
                image={getPopupImage(type)}
                imageWidth={type === 'Certificate' ? '310px' : '100px'}
                title={type === 'Certificate' ? 'Congratulations' : `${type} Completed`}
                description={type === 'Certificate' ? 'Certification Completed' : text}
                earnedPoints={`${points}${points > 1 ? ' points' : ' point'} earned in ${isAi ? 'xiQ GPT<sup>TM</sup>' : 'Sales Sigma'}`}
                buttonText={`Continue ${type !== 'Certificate' ? 'Certification' : ''}`}
                isAi={isAi}
              />
            ) : null}
            {apiCalled && _.isEmpty(popupResponse)
              && data.length && !certified && !isDiscLessPersonality && globalSearchTopicsLoaded
              ? <WelcomeTourPopup visible={showWelcome} /> : null}
            {!isDiscLessPersonality ? <DrawerCertification visible={showCertification} /> : null}
          </>
        ) : null}
      <ProfileConfirmationPopup />
      {showDisclaimer && (isMobile || isTablet)
        ? <LimitAccessPopup isIpPopup={false} /> : null}
      {visible && !(isMobile || isTablet || restrictAppAccess) ? <UserCompliancePopup /> : null}
      <StatesAndActionsWrapper getStatesAndActions={gilroyStatesAndActions} />
    </Header>
  );
};

export { chatStates, chatActions };
NavBarPrimary.defaultProps = defaultProps;
export default NavBarPrimary;
