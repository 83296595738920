import styled from 'styled-components';

type Props = {
  onClick?: () => void;
};

const defaultProps = {
  onClick: () => { },
};

const SVG = styled.svg`
`;

const FreshDeskIcon = function FreshDeskIcon(props: Props) {
  const { onClick } = props;
  return (
    <SVG width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <circle cx="16" cy="16" r="16" fill="white" />
      <path d="M15.9539 24.3751C15.0835 24.3459 14.4042 23.6139 14.4402 22.7452C14.4763 21.8852 15.1955 21.211 16.0464 21.2395C16.9168 21.2687 17.5961 22.0006 17.5601 22.8693C17.524 23.7293 16.8048 24.4036 15.9539 24.3751Z" fill="#424B50" />
      <path d="M14.7479 17.4281C14.7479 16.923 14.7424 16.4187 14.7494 15.9136C14.7596 15.1912 15.1882 14.6853 15.8996 14.6244C16.7246 14.5541 17.4532 14.3098 17.9657 13.6174C18.5744 12.7962 18.6825 11.8966 18.2328 10.9781C17.7666 10.0249 16.9691 9.53559 15.9113 9.57433C14.6311 9.62096 13.6894 10.5244 13.5225 11.8081C13.5076 11.9227 13.5029 12.0381 13.4927 12.1535C13.4285 12.8618 12.8784 13.3597 12.1929 13.3297C11.5081 13.3005 10.9832 12.7574 11.0004 12.046C11.0435 10.2588 11.7902 8.82736 13.278 7.86145C14.9523 6.77459 16.7301 6.70978 18.4585 7.70336C20.2464 8.73093 21.0847 10.3584 20.9876 12.4302C20.8928 14.4537 19.4848 16.2417 17.5441 16.8709C17.3169 16.9444 17.2347 17.0369 17.2441 17.2819C17.2644 17.8384 17.255 18.3964 17.2495 18.9537C17.2409 19.7267 16.7129 20.2785 15.9944 20.2753C15.2751 20.2721 14.7573 19.7212 14.7494 18.9426C14.7439 18.4375 14.7486 17.9332 14.7486 17.4281H14.7479Z" fill="#424B50" />
    </SVG>

  );
};

FreshDeskIcon.defaultProps = defaultProps;
export default FreshDeskIcon;
