import { lazy } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { SALESFORCE_COMPANY_SEARCH_ROUTE, SALESFORCE_PERSON_SEARCH_ROUTE } from 'Utils/Constants';
import { isSalesforce } from 'Utils/UtilityFunctions';

const Search = lazy(() => import('./GlobalSearchResults'));

const GlobalSearchRedirections = function GlobalSearchRedirections() {
  const [searchParams] = useSearchParams();
  const extendedSection = (searchParams.get('extend') ?? '');

  const query = searchParams.get('query') ?? '';

  if (isSalesforce()) {
    if (extendedSection === 'person') {
      return <Navigate to={`${SALESFORCE_PERSON_SEARCH_ROUTE}${query}`} state={{ from: 'salesforce-person-search' }} />;
    }
    return <Navigate to={`${SALESFORCE_COMPANY_SEARCH_ROUTE}${query}`} state={{ from: 'salesforce-search' }} />;
  }
  return <Search />;
};

export default GlobalSearchRedirections;
